<template>
    <div class="mx-4 my-4 md:mx-8">
        <Breadcrumb :links="breadcrumb" />
        <br />
        <h1 class="text-3xl mb-5">Números proibidos</h1>
        <div class="grid grid-cols-4 md:grid-cols-6 gap-3">
            <button @click="$modal.show('novosNumerosBlackList')" type="button"
                class="mb-2 bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-md text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">
                <span class="inline-block mr-2">{{ $t("txt-new") }}</span>
            </button>
            <button v-if="$store.state.user.admin" @click="exportarCsv" type="button"
            class="mb-2 bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-md text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">
            <span class="inline-block mr-2">Download</span>
          </button>
        </div>
        <form class="mb-2 mt-2">
      <div class="grid grid-cols-12 gap-3">
        <div class="col-span-12 md:col-span-3">
          <label for="busca" class="block text-sm font-medium text-gray-700">Número</label>
          <input v-model="search" type="text" name="search"
            class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
        </div>
        <div class="col-span-12 md:col-span-2">
          <button @click="setPage(1)" type="button"
            class="md:mt-7 bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-md text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">
            <span class="inline-block mr-2">Buscar</span>
          </button>
        </div>
      </div>
    </form>

        <div class="flex flex-col">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table class="min-w-full divide-y divide-gray-200">
                            <thead class="bg-gray-50">
                                <tr>
                                    <th scope="col"
                                        class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Número
                                    </th>
                                    <th scope="col"
                                        class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Data Incusão
                                    </th>
                                    <th scope="col"
                                        class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Opções
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                                <tr v-for="item in numeros" :key="item._id">
                                    <td class="text-sm 2xl:text-base px-2 py-3">
                                        {{ item.numero ? item.numero : "" }}
                                    </td>
                                    <td class="text-sm 2xl:text-base px-2 py-3">
                                        {{ item.dataInclusao ? moment(item.dataInclusao).format("DD/MM/YYYY") : "" }}
                                    </td>
                                    <td class="text-sm 2xl:text-base px-2 py-3">
                                        <button @click="remove(item._id)" type="button"
                                            class="bg-red-500 hover:bg-red-600 focus:bg-red-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 px-2 rounded-md text-sm shadow-sm hover:shadow-md font-semibold text-center">
                                            Remover
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <pagination v-model="page" :per-page="perPage" :records="total" @paginate="setPage" class="pagination" />
                </div>
            </div>
        </div>
        <modal name="novosNumerosBlackList" height="auto">
            <div class="px-3 py-4">
                <h2 class="text-lg mb-2 font-bold">Número(s) Proibido(s)</h2>
                <div class="grid grid-cols-12 gap-4">
                    <div class="col-span-12 md:col-span-12">
                        <a :href="`/arquivo-modelo-blacklists.xlsx`" type="button"
                            class="cursor-pointer mb-2 text-blue-700 py-2 text-sm font-semibold text-center">
                            <span class="inline-block mr-2 cursor-pointer">Download modelo</span>
                        </a>
                        <vue-dropzone required ref="doc" id="doc" class="w-full" :options="dropzoneCampaignOptions"
                            v-on:vdropzone-success="novosNumerosBlackList">
                        </vue-dropzone>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>
  
<script>
import Breadcrumb from "../../components/Breadcrumbs.vue";
import vueDropzone from "vue2-dropzone";
import moment from "moment"

export default {
    components: {
        Breadcrumb,
        vueDropzone
    },
    data() {
        return {
            search: "",
            breadcrumb: [
                { url: "/operadores", value: "Operadores" },
                { url: "/operadores/numerosproibidos", value: "Números Proibidos" },
            ],
            moment:moment,
            route: "operadores",
            numeros: [],
            total: 0,
            dropzoneCampaignOptions: this.$http.getDropzoneConfig(
                `numeros-proibidos/novosProibidos`,
                "post",
                {
                    maxFiles: 1,
                    addRemoveLinks: true,
                    capture: true,
                    dictDefaultMessage: `Clique ou arraste o arquivo da campanha aqui`,
                    dictRemoveFile: "Remover",
                }
            ),
            page: 1,
            perPage: 20,
        };
    },
    methods: {
        async exportarCsv() {
            try {
            const response = await this.$http.get('/v1/numeros-proibidos/downloadCSV', {
                responseType: 'blob' // importante para lidar com o arquivo
            });
            
            // Cria um link temporário para download
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `numeros-proibidos-${new Date().toISOString().split('T')[0]}.csv`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            
            this.$vToastify.success('Exportação concluída com sucesso!');
            } catch (error) {
            this.$vToastify.error('Erro ao exportar os números');
            console.error(error);
            }
        },
        async start() {
            const operadoresReq = await this.$http.post("/v1/numeros-proibidos/list", {limit: this.perPage} );
            this.numeros = operadoresReq.data.data;
            this.total = operadoresReq.data.total;
            this.page = 1;
        },

        async setPage(page) {
            this.page = page;

            const skip = (page - 1) * this.perPage;
            const limit = this.perPage;
            
            console.log(this.search);

            const operadoresReq = await this.$http.post(`/v1/numeros-proibidos/list`, {
                search: this.search,
                limit,
                skip,
            });

            this.numeros = operadoresReq.data.data;
            this.total = operadoresReq.data.total;
        },

        async novosNumerosBlackList(file, response) {
            !response.success ? this.$vToastify.error(response.err)
                : this.$vToastify.success(`Arquivo ${file.name} cadastrado!`);
            
            this.$modal.hide('novosNumerosBlackList');
            this.start();
        },

        async remove(id) {
            if (confirm("Tem certeza que deseja excluir esse número?")) {
                const resp = await this.$http.delete(`/v1/numeros-proibidos/${id}`);
                if (!resp.data.success) {
                    this.$vToastify.error(resp.data.err);
                    return;
                }
                this.start();
                this.$vToastify.success("Número removido");
            }
        },
    },
    async beforeMount() {
        this.start();
    },
};
</script>
  